<template>
    <div class="imprint-wrapper">
        <div class="container">
            <br>
            <h1>Imprint</h1>
            <p>Information in accordance with §5 of the E-Commerce Act, §14 of the Unternehmensgesetzbuch, §63 of the
                Commercial Code and disclosure requirements under §25 of the Media Act.</p>
            <p>
            <p>Nils Schneider-Sturm</p>
            <p>Wien, <br/>Österreich</p>
            <p>
                <strong>Email:</strong> <a :href="'mailto:' + this.$config.email_business" decorated>{{
                    this.$config.email_business
                }}</a>
            </p>
            <p style="margin-top:15px;">Source: Created with the Impressum Generator by <a decorated
                                                                                           href="https://www.adsimple.at/webdesign/"
                                                                                           rel="follow"
                                                                                           target="_blank" title="AdSimple® Online Marketing and Webdesign">AdSimple®
                Webdesign</a> in cooperation with <a decorated href="https://www.aboutbusiness.at/" rel="follow"
                                                     target="_blank">aboutbusiness.at</a>
            </p>
            <h2 id="eu-streitschlichtung-en">
            </h2>
            <h2 id="haftung-fuer-inhalte-dieser-webseite-en">
            </h2>
            <h2 id="haftung-links-webseite-en">
            </h2>
            <h2 id="urheberrechtshinweis-en">
            </h2>
            <h2 id="bildernachweis-en">
            </h2>
            <h1>Privacy policy</h1>
            <h2 id="datenschutz-en">Privacy Policy</h2>
            <p>We created this Privacy Policy (version 01.01.1970-121400314), to declare which information we collect,
                how we use data and which options the users of our website have, according to the guidelines of the <a
                        decorated
                        href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32016R0679&amp%3Btid= 121400314"
                        rel="noopener" target="_blank">General Data Protection Regulation (EU) 2016/679</a>
            </p>
            <p>Unfortunately, these subjects sound rather technical due to their nature, but we have put much effort
                into describing the most important things as simply and clearly as possible.</p>
            <h2 id="automatische-datenspeicherung-en">Automatic Data Retention</h2>
            <p>Every time you visit a website nowadays, certain information is automatically created and saved, just as
                it happens on this website.</p>
            <p>Whenever you visit our website such as you are doing right now, our webserver (computer on which this
                website is saved/stored) automatically saves data such as</p>
            <ul>
                <li>the address (URL) of the accessed website</li>
                <li>browser and browser version</li>
                <li>the used operating system</li>
                <li>the address (URL) of the previously visited site (referrer URL)</li>
                <li>the host name and the IP-address of the device the website is accessed from</li>
                <li>date and time</li>
            </ul>
            <p>in files (webserver-logfiles).</p>
            <p>Generally, webserver-logfiles stay saved for two weeks and then get deleted automatically. We do not pass
                this information to others, but we cannot exclude the possibility that this data will be looked at in
                case of illegal conduct.</p>
            <h2 id="cookies-en">Cookies</h2>
            <p>Our website uses HTTP-cookies to store user-specific data.<br/>
                For your better understanding of the following Privacy Policy statement, we will explain to you below
                what cookies are and why they are in use.</p>
            <h3>What exactly are cookies?</h3>
            <p>Every time you surf the internet, you use a browser. Common browsers are for example Chrome, Safari,
                Firefox, Internet Explorer and Microsoft Edge. Most websites store small text-files in your browser.
                These files are called cookies.</p>
            <p>What should not be dismissed, is that cookies are very useful little helpers. Nearly all websites use
                cookies. More accurately speaking these are HTTP-cookies, since there are also different cookies for
                other uses. http-cookies are small files which our website stores on your computer. These cookie files
                are automatically put into the cookie-folder, which is like the “brain” of your browser. A cookie
                consists of a name and a value. Moreover, to define a cookie, one or multiple attributes must be
                specified.</p>
            <p>Cookies save certain parts of your user data, such as e.g. language or personal page settings. When you
                re-open our website, your browser submits these “user specific” information back to our site. Thanks to
                cookies, our website knows who you are and offers you the settings you are familiar to. In some browsers
                every cookie has its own file, in others such as Firefox, all cookies are stored in one single file.</p>
            <p>There are both first-party cookies and third-party coookies. First-party cookies are created directly by
                our site, while third-party cookies are created by partner-websites (e.g. Google Analytics). Every
                cookie is individual, since every cookie stores different data. The expiration time of a cookie also
                varies – it can be a few minutes, or up to a few years. Cookies are no software-programs and contain no
                computer viruses, trojans or any other malware. Cookies also cannot access your PC’s information.</p>
            <p>This is an example of how cookie-files can look:</p>
            <p>
                <strong>name:</strong> _ga<br/>
                <strong>value:</strong> GA1.2.1326744211.152121400314-9<br/>
                <strong>purpose:</strong> differentiation between website visitors<br/>
                <strong>expiration date:</strong> after 2 years</p>
            <p>A browser should support these minimum sizes:</p>
            <ul>
                <li>at least 4096 bytes per cookie</li>
                <li>at least 50 cookies per domain</li>
                <li>at least 3000 cookies in total</li>
            </ul>
            <h3>Which types of cookies are there?</h3>
            <p>What exact cookies we use, depends on the used services. We will explain this in the following sections
                of the Privacy Policy statement. Firstly, we will briefly focus on the different types of
                HTTP-cookies.</p>
            <p>There are 4 different types of cookies:</p>
            <p>
                <strong>Essential Cookies<br/>
                </strong>These cookies are necessary to ensure the basic function of a website. They are needed when a
                user for example puts a product into their shopping cart, then continues surfing on different websites
                and comes back later in order to proceed to the checkout. Even when the user closed their window
                priorly, these cookies ensure that the shopping cart does not get deleted.</p>
            <p>
                <strong>Purposive Cookies<br/>
                </strong>These cookies collect info about the user behaviour and record if the user potentially receives
                any error messages. Furthermore, these cookies record the website’s loading time as well as its
                behaviour within different browsers.</p>
            <p>
                <strong>Target-orientated Cookies<br/>
                </strong>These cookies care for an improved user-friendliness. Thus, information such as previously
                entered locations, fonts or data in forms stay saved.</p>
            <p>
                <strong>Advertising Cookies<br/>
                </strong>These cookies are also known as targeting-Cookies. They serve the purpose of delivering
                individually adapted advertisements to the user. This can be very practical, but also rather annoying.
            </p>
            <p>Upon your first visit to a website you are usually asked which of these cookie-types you want to accept.
                Furthermore, this decision will of course also be saved in a cookie.</p>
            <h3>How can I delete cookies?</h3>
            <p>You yourself take the decision if and how you want to use cookies. Thus, no matter what service or
                website cookies are from, you always have the option to delete, deactivate or only partially allow them.
                Therefore, you can for example block cookies of third parties but allow any other cookies.</p>
            <p>If you want change or delete cookie-settings and would like to determine which cookies have been saved to
                your browser, you can find this info in your browser-settings:</p>
            <p>
                <a decorated href="https://support.google.com/chrome/answer/95647?tid=121400314" rel="noopener"
                   target="_blank"> Chrome: Clear, enable and manage cookies in Chrome </a>
            </p>
            <p>
                <a decorated href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121400314"
                   rel="noopener" target="_blank"> Safari: Manage cookies and website data in Safari </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121400314"
                   rel="noopener" target="_blank"> Firefox: Clear cookies and site data in Firefox </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121400314"
                   rel="noopener" target="_blank"> Internet Explorer: Delete and manage cookies </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121400314"
                   rel="noopener" target="_blank"> Microsoft Edge: Delete cookies in Microsoft Edge </a>
            </p>
            <p>If you generally do not want to allow any cookies at all, you can set up your browser in a way, to notify
                you whenever a potential cookie is about to be set. This gives you the opportunity to manually decide to
                either permit or deny the placement of every single cookie. The settings for this differ from browser to
                browser. Therefore, it might be best for you to search for the instructions in Google. If you are using
                Chrome, you could for example put the search phrase “delete cookies Chrome” or “deactivate cookies
                Chrome” into Google.</p>
            <h3>How is my data protected?</h3>
            <p>There is a “cookie policy” that has been in place since 2009. It states that the storage of cookies
                requires the user’s consent. However, among the countries of the EU, these guidelines are often met with
                mixed reactions. In Austria the guidelines have been implemented in § 96 section 3 of the
                Telecommunications Act (TKG).</p>
            <p>If you want to learn more about cookies and do not mind technical documentation, we recommend <a
                    decorated href="https://tools.ietf.org/html/rfc6265" rel="nofollow noopener" target="_blank">https://tools.ietf.org/html/rfc6265</a>,
                the Request for Comments of the Internet Engineering Task Force (IETF) called “HTTP State Management
                Mechanism”.</p>
            <h2 id="speicherung-persoenlicher-daten-en">Storage of Personal Data</h2>
            <p>Any personal data you electronically submit to us on this website, such as your name, email address, home
                address or other personal information you provide via the transmission of a form or via any comments to
                the blog, are solely used for the specified purpose and get stored securely along with the respective
                submission times and IP-address. These data do not get passed on to third parties.</p>
            <p>Therefore, we use personal data for the communication with only those users, who have explicitly
                requested being contacted, as well as for the execution of the services and products offered on this
                website. We do not pass your personal data to others without your approval, but we cannot exclude the
                possibility this data will be looked at in case of illegal conduct.</p>
            <p>If you send us personal data via email – and thus not via this website – we cannot guarantee any safe
                transmission or protection of your data. We recommend you, to never send confidential data via
                email.</p>
            <h2 id="rechte-dsgvo-en">Rights in accordance with the General Data Protection Regulation</h2>
            <p>You are granted the following rights in accordance with the provisions of the <a decorated
                                                                                                href="https://gdpr-info.eu/"
                                                                                                rel="noopener"
                                                                                                target="_blank">GDPR</a>
                (General Data Protection Regulation) and the Austrian <a decorated
                                                                         href="https://www.ris.bka.gv.at/Dokumente/Erv/ERV_1999_1_165/ERV_1999_1_165.html"
                                                                         rel="noopener" target="_blank">Data Protection
                    Act (DSG)</a>:</p>
            <ul>
                <li>right to rectification (article 16 GDPR)</li>
                <li>right to erasure (“right to be forgotten“) (article 17 GDPR)</li>
                <li>right to restrict processing (article 18 GDPR)</li>
                <li>righ to notification – notification obligation regarding rectification or erasure of personal data
                    or restriction of processing (article 19 GDPR)
                </li>
                <li>right to data portability (article 20 GDPR)</li>
                <li>Right to object (article 21 GDPR)</li>
                <li>right not to be subject to a decision based solely on automated processing – including profiling –
                    (article 22 GDPR)
                </li>
            </ul>
            <p>If you think that the processing of your data violates the data protection law, or that your data
                protection rights have been infringed in any other way, you can lodge a complaint with your respective
                regulatory authority. For Austria this is the data protection authority, whose website you can access at
                <a decorated href="https://www.data-protection-authority.gv.at/?tid=121400314">https://www.data-protection-authority.gv.at/</a>.
            </p>
            <h2 id="auswertung-des-besucherverhaltens-en">Evaluation of Visitor Behaviour</h2>
            <p>In the following Privacy Policy, we will inform you on if and how we evaluate the data of your visit to
                this website. The evaluation is generally made anonymously, and we cannot link to you personally based
                on your behaviour on this website.</p>
            <p>You can find out more about how to disagree with the evaluation of visitor data, in the Privacy Policy
                below.</p>
            <h2 id="tls-verschluesselung-https-en">TLS encryption with https</h2>
            <p>We use https to transfer information on the internet in a tap-proof manner (data protection through
                technology design <a decorated
                                     href="https://eur-lex.europa.eu/legal-content/en/TXT/HTML/?uri=CELEX:32016R0679&from=EN&tid=121400314"
                                     rel="noopener" target="_blank">Article 25 Section 1 GDPR</a>). With the use of TLS
                (Transport Layer Security), which is an encryption protocol for safe data transfer on the internet, we
                can ensure the protection of confidential information. You can recognise the use of this safeguarding
                tool by the little lock-symbol, which is situated in your browser’s top left corner, as well as by the
                use of the letters https (instead of http) as a part of our web address.</p>
            <h2 id="google-analytics-datenschutzerklaerung-en">Google Analytics Privacy Policy</h2>
            <p>We use the tracking and analysis tool Google Analytics (GA) of the US-American company Google LLC (1600
                Amphitheatre Parkway Mountain View, CA 94043, USA). Google Analytics collects data on your actions on
                our website. Whenever you click a link for example, this action is saved in a cookie and transferred to
                Google Analytics. With the help of reports which we receive from Google Analytics, we can adapt our
                website and our services better to your wishes. In the following, we will explain the tracking tool in
                more detail, and most of all, we will inform you what data is saved and how you can prevent this.</p>
            <h3>What is Google Analytics?</h3>
            <p>Google Analytics is a tracking tool with the purpose of conducting data traffic analysis of our website.
                For Google Analytics to work, there is a tracking code integrated to our website. Upon your visit to our
                website, this code records various actions you perform on your website. As soon as you leave our
                website, this data is sent to the Google Analytics server, where it is stored.</p>
            <p>Google processes this data and we then receive reports on your user behaviour. These reports can be one
                of the following:</p>
            <ul>
                <li>Target audience reports: With the help of target audience reports we can get to know our users
                    better and can therefore better understand who is interested in our service.
                </li>
                <li>Advertising reports: Through advertising reports we can analyse our online advertising better and
                    hence improve it.
                </li>
                <li>Acquisition reports: Acquisition reports provide us helpful information on how we can get more
                    people enthusiastic about our service.
                </li>
                <li>Behaviour reports: With these reports, we can find out how you interact with our website. By the
                    means of behaviour reports, we can understand what path you go on our website and what links you
                    click.
                </li>
                <li>Conversion reports: A conversion is the process of leading you to carry out a desired action due to
                    a marketing message. An example of this would be transforming you from a mere website visitor into a
                    buyer or a newsletter subscriber. Hence, with the help of these reports we can see in more detail,
                    if our marketing measures are successful with you. Our aim is to increase our conversion rate.
                </li>
                <li>Real time reports: With the help of these reports we can see in real time, what happens on our
                    website. It makes us for example see, we can see how many users are reading this text right now.
                </li>
            </ul>
            <h3>Why do we use Google Analytics on our website?</h3>
            <p>The objective of our website is clear: We want to offer you the best possible service. Google Analytics’
                statistics and data help us with reaching this goal.</p>
            <p>Statistically evaluated data give us a clear picture of the strengths and weaknesses of our website. On
                the one hand, we can optimise our page in a way, that makes it easier to be found by interested people
                on Google. On the other hand, the data helps us to get a better understanding of you as our visitor.
                Therefore, we can very accurately find out what we must improve on our website, in order to offer you
                the best possible service. The analysis of that data also enables us to carry out our advertising and
                marketing measures in a more individual and more cost-effective way. After all, it only makes sense to
                show our products and services exclusively to people who are interested in them.</p>
            <h3>What data gets stored by Google Analytics?</h3>
            <p>With the aid of a tracking code, Google Analytics creates a random, unique ID which is connected to your
                browser cookie. That way, Google Analytics recognises you as a new user. The next time you visit our
                site, you will be recognised as a “recurring” user. All data that is collected gets saved together with
                this very user ID. Only this is how it is made possible for us to evaluate and analyse pseudonymous user
                profiles.</p>
            <p>Your interactions on our website are measures by tags such as cookies and app instance IDs. Interactions
                are all kinds of actions that you perform on our website. If you are also using other Google systems
                (such as a Google Account), data generated by Google Analytics can be linked with third-party cookies.
                Google does not pass on any Google Analytics data, unless we as the website owners authorise it. In case
                it is required by law, exceptions can occur.</p>
            <p>The following cookies are used by Google Analytics:</p>
            <p>
                <strong>Name:</strong> _ga<br/>
                <strong>Value:</strong>2.1326744211.152121400314-5<br/>
                <strong>Purpose:</strong> By deafault, analytics.js uses the cookie _ga, to save the user ID. It
                generally serves the purpose of differenciating between website visitors.<br/>
                <strong>Expiration date:</strong> After 2 years</p>
            <p>
                <strong>Name:</strong> _gid<br/>
                <strong>Value:</strong>2.1687193234.152121400314-1<br/>
                <strong>Purpose:</strong> This cookie also serves the purpose of differentiating between website
                users<br/>
                <strong>Expiration date:</strong> After 24 hours</p>
            <p>
                <strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br/>
                <strong>Value:</strong> 1<br/>
                <strong>Verwendungszweck:</strong> It is used for decreasing the demand rate. If Google Analytics is
                provided via Google Tag Manager, this cookie gets the name _dc_gtm_ &lt;property-id&gt;.<br/>
                <strong>Expiration date: </strong>After 1 minute</p>
            <p>
                <strong>Name:</strong> AMP_TOKEN<br/>
                <strong>Value:</strong> No information<br/>
                <strong>Purpose:</strong> This cookie has a token which is used to retrieve the user ID by the AMP
                Client ID Service. Other possible values suggest a logoff, a request or an error.<br/>
                <strong>Expiration date:</strong> After 30 seconds up to one year</p>
            <p>
                <strong>Name:</strong> __utma<br/>
                <strong>Value:</strong>1564498958.1564498958.1564498958.1<br/>
                <strong>Purpose:</strong> With this cookie your behaviour on the website can be tracked and the site
                performance can be measured. The cookie is updated every time the information is sent to Google
                Analytics.<br/>
                <strong>Expiration date:</strong> After 2 years</p>
            <p>
                <strong>Name:</strong> __utmt<br/>
                <strong>Value:</strong> 1<br/>
                <strong>Purpose:</strong> Just like _gat_gtag_UA_&lt;property-id&gt; this cookie is used for keeping the
                requirement rate in check.<br/>
                <strong>Expiration date:</strong> Afer 10 minutes</p>
            <p>
                <strong>Name:</strong> __utmb<br/>
                <strong>Value:</strong>3.10.1564498958<br/>
                <strong>Purpose:</strong> This cookie is used to determine new sessions. It is updated every time new
                data or information gets sent to Google Analytics.<br/>
                <strong>Expiration date:</strong> After 30 minutes</p>
            <p>
                <strong>Name:</strong> __utmc<br/>
                <strong>Value:</strong> 167421564<br/>
                <strong>Purpose:</strong> This cookie is used to determine new sessions for recurring visitors. It is
                therefore a session cookie, and only stays saved until you close the browser again.<br/>
                <strong>Expiration date:</strong> After closing the browser</p>
            <p>
                <strong>Name:</strong> __utmz<br/>
                <strong>Value:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br/>
                <strong>Purpose:</strong> This cookie is used to identify the source of our website’s visitor number.
                This means, that the cookie saves information on where you came to our website from. This could be
                another site or an advertisement.<br/>
                <strong>Expiration date:</strong> After 6 months</p>
            <p>
                <strong>Name:</strong> __utmv<br/>
                <strong>Value:</strong> No information<br/>
                <strong>Purpose:</strong> The cookie is used to store custom user data. It gets updated whenever
                information is sent to Google Analytics.<br/>
                <strong>Expiration date:</strong> After 2 years</p>
            <p>
                <strong>Note:</strong> This list is by no means exhaustive, since Google are repeatedly changing the use
                of their cookies.</p>
            <p>Below we will give you an overview of the most important data that can be evaluated by Google
                Analytics:</p>
            <p>
                <strong>Heatmaps:</strong> Google creates so-called Heatmaps an. These Heatmaps make it possible to see
                the exact areas you click on, so we can get information on what routes you make on our website.</p>
            <p>
                <strong>Session duration:</strong> Google calls the time you spend on our website without leaving it
                session duration. Whenever you are inactive for 20 minutes, the session ends automatically.</p>
            <p>
                <strong>Bounce rate</strong> If you only look at one page of our website and then leave our website
                again, it is called a bounce.</p>
            <p>
                <strong>Account creation:</strong> If you create an account or make an order on our website, Google
                Analytics collects this data.</p>
            <p>
                <strong>IP-Address:</strong> The IP address is only shown in a shortened form, to make it impossible to
                clearly allocate it.</p>
            <p>
                <strong>Location:</strong> Your approximate location and the country you are in can be defined by the IP
                address. This process is called IP location determination.</p>
            <p>
                <strong>Technical information:</strong> Information about your browser type, your internet provider and
                your screen resolution are called technical information.</p>
            <p>
                <strong>Source:</strong> Both, Google Analytics as well as ourselves, are interested what website or
                what advertisement led you to our site.</p>
            <p>Further possibly stored data includes contact data, potential reviews, playing media (e.g. when you play
                a video on our site), sharing of contents via social media or adding our site to your favourites. This
                list is not exhaustive and only serves as general guidance on Google Analytics’ data retention.</p>
            <h3>How long and where is the data saved?</h3>
            <p>Google has servers across the globe. Most of them are in America and therefore your data is mainly saved
                on American servers. Here you can read detailed information on where Google’s data centres are located:
                <a decorated href="https://www.google.com/about/datacenters/inside/locations/?hl=en" rel="noopener"
                   target="_blank">https://www.google.com/about/datacenters/inside/locations/?hl=en</a>
            </p>
            <p>Your data is allocated to various physical data mediums. This has the advantage of allowing to retrieve
                the data faster, and of protecting it better from manipulation. Every Google data centre has respective
                emergency programs for your data. Hence, in case of a hardware failure at Google or a server error due
                to natural disasters, the risk for a service interruption stays relatively low.</p>
            <p>Google Analytics has a 26 months standardised period of retaining your user data. After this time, your
                user data is deleted. However, we have the possibility to choose the retention period of user data
                ourselves. There are the following five options:</p>
            <ul>
                <li>Deletion after 14 months</li>
                <li>Deletion after 26 months</li>
                <li>Deletion after 38 months</li>
                <li>Deletion after 50 months</li>
                <li>No automatical deletion</li>
            </ul>
            <p>As soon as the chosen period is expired, the data is deleted once a month. This retention period applies
                to any of your data which is linked to cookies, user identification and advertisement IDs (e.g. cookies
                of the DoubleClick domain). Any report results are based on aggregated information and are stored
                independently of any user data. Aggregated information is a merge of individual data into a single and
                bigger unit.</p>
            <h3>How can I delete my data or prevent data retention?</h3>
            <p>Under the provisions of the European Union’s data protection law, you have the right to obtain
                information on your data and to update, delete or restrict it. With the help of a browser add on that
                can deactivate Google Analytics’ JavaScript (ga.js, analytics.js, dc.js), you can prevent Google
                Analytics from using your data. You can download this add on at <a decorated
                                                                                   href="https://tools.google.com/dlpage/gaoptout?hl=en-GB"
                                                                                   rel="noopener" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=en-GB</a>.
                Please consider that this add on can only deactivate any data collection by Google Analytics.</p>
            <p>Should you generally want to deactivate, delete or manage all cookies (independently of Google
                Analytics), you can use one of the guides that are available for any browser:</p>
            <p>
                <a decorated href="https://support.google.com/chrome/answer/95647?tid=121400314" rel="noopener"
                   target="_blank"> Chrome: Clear, enable and manage cookies in Chrome </a>
            </p>
            <p>
                <a decorated href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121400314"
                   rel="noopener" target="_blank"> Safari: Manage cookies and website data in Safari </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121400314"
                   rel="noopener" target="_blank"> Firefox: Clear cookies and site data in Firefox </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121400314"
                   rel="noopener" target="_blank"> Internet Explorer: Delete and manage cookies </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121400314"
                   rel="noopener" target="_blank"> Microsoft Edge: Delete cookies in Microsoft Edge </a>
            </p>
            <p>Google Analytics is an active participant of the EU-U.S. Privacy Shield Framework, which regulates
                correct and save transfer of personal data.<br/>
                You can find more information on this at <a decorated
                                                            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
                                                            rel="follow noopener" target="_blank">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=121400314</a>.
                We hope we were able to make you more familiar with the most important information on Google Analytics’
                data processing. If you want to learn more about the tracking service, we recommend both of the
                following links: <a decorated href="https://marketingplatform.google.com/about/analytics/terms/gb/"
                                    rel="noopener" target="_blank">https://marketingplatform.google.com/about/analytics/terms/gb/</a>
                and <a decorated href="https://support.google.com/analytics/answer/6004245?hl=en" rel="noopener"
                       target="_blank">https://support.google.com/analytics/answer/6004245?hl=en</a>.</p>
            <h2 id="eingebettete-social-media-elemente-datenschutzerklaerung-en">Embedded Social Media elements Privacy
                Policy</h2>
            <p>We have embedded elements from social media services on our website, to display pictures, videos and
                texts. By visiting pages that present such elements, data is transferred from your browser to the
                respective social media service, where it is stored. We do not have access to this data.<br/>
                The following links lead to the respective social media services’ sites, where you can find a
                declaration on how they handle your data:</p>
            <ul>
                <li>Instagram Data Policy: <a decorated href="https://help.instagram.com/519522125107875?tid=121400314"
                                              rel="noopener"
                                              target="_blank">https://help.instagram.com/519522125107875</a>
                </li>
                <li>For YouTube, the Google Privacy Policy applies: <a decorated
                                                                       href="https://policies.google.com/privacy?hl=en-GB&tid=121400314"
                                                                       rel="noopener" target="_blank">https://policies.google.com/privacy?hl=en-GB</a>
                </li>
                <li>Facebook Data Policy: <a decorated href="https://www.facebook.com/about/privacy?tid=121400314"
                                             rel="noopener" target="_blank">https://www.facebook.com/about/privacy</a>
                </li>
                <li>Twitter Privacy Policy: <a decorated href="https://twitter.com/en/privacy?tid=121400314"
                                               rel="noopener" target="_blank">https://twitter.com/en/privacy</a>
                </li>
            </ul>
            <h2 id="gravatar-datenschutzerklaerung-en">Gravatar Privacy Policy</h2>
            <p>We have integrated the Gravatar plugin from Automattic Inc. (60 29th Street # 343, San Francisco, CA
                94110, USA) into our website. Gravatar is automatically activated on all WordPress websites. The
                function enables user images (avatars) to be displayed in published articles or comments, provided the
                respective email address is registered at <a decorated href="https://en.gravatar.com/?tid=121400314"
                                                             rel="follow noopener" target="_blank">www.gravatar.com</a>.
            </p>
            <p>This function sends data to Gravatar or Automattic Inc. where it gets stored and processed. In this
                privacy statement we would like to inform you what data this is, how the network uses it and how you can
                manage or prevent data retention.</p>
            <h3>What is Gravatar?</h3>
            <p>Gravatar stands for “Globally Recognized Avatar” which is a globally available avatar (a user picture)
                that is linked to an email address. The company Gravatar is the world’s leading provider for this
                service. As soon as a user enters their email address which is also registered with <a decorated
                                                                                                       href="https://en.gravatar.com/?tid=121400314"
                                                                                                       rel="follow noopener"
                                                                                                       target="_ blank">www.gravatar.com</a>
                to a website, the user’s previously stored image is automatically displayed with their post or comment.
            </p>
            <h3>Why do we use Gravatar on our website?</h3>
            <p>Anonymity on the internet is a popular topic. An avatar gives people that make posts or comments a face.
                Moreover, it makes it easier to be recognised on the web and to make oneself known. Many users enjoy the
                advantages of user pictures as they want to appear on the web in a personal and authentic manner. Of
                course, we want to offer you the possibility to display your Gravatar also on our website. Furthermore,
                we also like to see faces of our commenting users. With the activated Gravatar function, we are
                expanding the service on our website. After all, we want you to feel comfortable on our website and
                enable you to receive an extensive and interesting offer.</p>
            <h3>What data is retained by Gravatar?</h3>
            <p>When you publish a comment to a blogpost which requires an email address, WordPress checks whether your
                email address is linked to an avatar on Gravatar. For this, your email address gets encrypted and hashed
                and sent to Gravatar’s or Automattic’s servers, together with your IP address and our URL. Then Gravatar
                will check whether the email address is registered with the platform.</p>
            <p>If the email address is registered with Gravatar, the image (gravatar) stored there will be displayed in
                the published comment. If you have registered your email address with Gravatar and comment on our
                website, further data will be transmitted to Gravatar, where it will be saved and processed. In addition
                to IP address and user behaviour data, this includes e.g. your browser type, the unique device
                identification, your preferred language, the data and time of the page visit, your operating system and
                information on the mobile network. Gravatar use this information to improve their services and offers
                and to gain better insight into the use of their service.</p>
            <p>The following cookies are set by Automattic when a user enters an email address that is registered with
                Gravatar, for submitting a comment:</p>
            <p>
                <strong>Name:</strong> gravatar<br/>
                <strong>Value:</strong> 16b3191024acc05a238209d51ffcb92bdd710bd19121400314-7<br/>
                <strong>Purpose: </strong>We could not find any exact information about the cookie.<br/>
                <strong>Expiry date: </strong>after 50 years</p>
            <p>
                <strong>Name:</strong> is-logged-in<br/>
                <strong>Value: </strong>1121400314-1<br/>
                <strong>Purpose: </strong>This cookie stores the information that the user is logged in via the
                registered email address.<br/>
                <strong>Expiry date: </strong>after 50 years</p>
            <h3>How long and where is the data retained?</h3>
            <p>Automattic deletes the collected data either if they are no longer used for their services, or if the
                company is not legally obliged to keep the data. Web server logs such as IP addresses, browser types and
                operating systems will be deleted after about 30 days. Until deletion, Automattic use the data to
                analyse traffic on their own websites (for example all WordPress sites) and to fix potential problems.
                The data is also stored on Automattic’s American servers.</p>
            <h3>How can I delete my data or prevent data retention?</h3>
            <p>You have the right to access and delete your personal data at any time. If you have registered with
                Gravatar with an email address, you can delete your account or email address there at any time.</p>
            <p>Since images are only displayed when using an email address registered with Gravatar, and data is
                therefore transferred to Gravatar, you can prevent transmission of your data to Gravatar by submitting
                comments or articles on our website with an email address that is not registered with Gravatar.</p>
            <p>You can manage, deactivate or delete cookies that may be set in your browser when commenting. Please note
                that in this case comment functions may no longer be available in their intended scope. Depending on the
                browser you use, the management of cookies works a little different. You can find the instructions for
                the most common browsers here:</p>
            <p>
                <a decorated href="https://support.google.com/chrome/answer/95647?tid=121400314" rel="noopener"
                   target="_blank"> Chrome: Clear, enable and manage cookies in Chrome </a>
            </p>
            <p>
                <a decorated href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121400314"
                   rel="noopener" target="_blank"> Safari: Manage cookies and website data in Safari </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121400314"
                   rel="noopener" target="_blank"> Firefox: Clear cookies and site data in Firefox </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121400314"
                   rel="noopener" target="_blank"> Internet Explorer: Delete and manage cookies </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121400314"
                   rel="noopener" target="_blank"> Microsoft Edge: Delete cookies in Microsoft Edge </a>
            </p>
            <p>Automattic is an active participant in the EU-U.S. Privacy Shield Framework which regulates correct and
                secure transfer of personal data. You can find more information on this at <a decorated
                                                                                              href="https://www.privacyshield.gov/participant?id=a2zt0000000CbqcAAC"
                                                                                              rel="noopener"
                                                                                              target="_blank">https://www.privacyshield.gov/participant?id=a2zt0000000CbqcAAC</a>
                .<br/>
                You can find more details on the privacy policy and what data is collected by Gravatar at <a decorated
                                                                                                             href="https://automattic.com/privacy/?tid=[adsimpletextid["
                                                                                                             rel="noopener"
                                                                                                             target="_blank">
                    https://automattic.com/privacy/ </a>. Moreover, at <a decorated href="https://en.gravatar.com/"
                                                                          rel="noopener" target="_blank">https://en.gravatar.com/</a>
                you can find general information on Gravatar.</p>
            <h2 id="youtube-datenschutzerklaerung-en">YouTube Privacy Policy</h2>
            <p>We have integrated YouTube videos to our website. Therefore, we can show you interesting videos directly
                on our site. YouTube is a video portal, which has been a subsidiary company of Google LLC since 2006.
                The video portal is operated by YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. When you visit
                a page on our website that contains an embedded YouTube video, your browser automatically connects to
                the servers of YouTube or Google. Thereby, certain data are transferred (depending on the settings).
                Google is responsible for YouTube’s data processing and therefore Google’s data protection applies.</p>
            <p>In the following we will explain in more detail which data is processed, why we have integrated YouTube
                videos and how you can manage or clear your data.</p>
            <h3>What is YouTube?</h3>
            <p>On YouTube, users can watch, rate, comment or upload videos for free. Over the past few years, YouTube
                has become one of the most important social media channels worldwide. For us to be able to display
                videos on our website, YouTube provides a code snippet that we have integrated to our website.</p>
            <h3>Why do we use YouTube videos on our website?</h3>
            <p>YouTube is the video platform with the most visitors and best content. We strive to offer you the best
                possible user experience on our website, which of course includes interesting videos. With the help of
                our embedded videos, we can provide you other helpful content in addition to our texts and images.
                Additionally, embedded videos make it easier for our website to be found on the Google search engine.
                Moreover, if we place ads via Google Ads, Google only shows these ads to people who are interested in
                our offers, thanks to the collected data.</p>
            <h3>What data is stored by YouTube?</h3>
            <p>As soon as you visit one of our pages with an integrated YouTube, YouTube places at least one cookie that
                stores your IP address and our URL. If you are logged into your YouTube account, by using cookies
                YouTube can usually associate your interactions on our website with your profile. This includes data
                such as session duration, bounce rate, approximate location, technical information such as browser type,
                screen resolution or your Internet provider. Additional data can include contact details, potential
                ratings, shared content via social media or YouTube videos you added to your favourites.</p>
            <p>If you are not logged in to a Google or YouTube account, Google stores data with a unique identifier
                linked to your device, browser or app. Thereby, e.g. your preferred language setting is maintained.
                However, many interaction data cannot be saved since less cookies are set.</p>
            <p>In the following list we show you cookies that were placed in the browser during a test. On the one hand,
                we show cookies that were set without being logged into a YouTube account. On the other hand, we show
                you what cookies were placed while being logged in. We do not claim for this list to be exhaustive, as
                user data always depend on how you interact with YouTube.</p>
            <p>
                <strong>Name:</strong> YSC<br/>
                <strong>Value:</strong> b9-CV6ojI5Y121400314-1<br/>
                <strong>Purpose:</strong> This cookie registers a unique ID to store statistics of the video that was
                viewed.<br/>
                <strong> Expiry date:</strong> after end of session</p>
            <p>
                <strong>Name:</strong> PREF<br/>
                <strong> Value:</strong> f1=50000000<br/>
                <strong> Purpose:</strong> This cookie also registers your unique ID. Google receives statistics via
                PREF on how you use YouTube videos on our website.<br/>
                <strong> Expiry date:</strong> after 8 months</p>
            <p>
                <strong>Name:</strong> GPS<br/>
                <strong> Value:</strong> 1<br/>
                <strong> Purpose:</strong> This cookie registers your unique ID on mobile devices to track GPS
                locations.<br/>
                <strong>Expiry date:</strong> after 30 minutes</p>
            <p>
                <strong>Name:</strong> VISITOR_INFO1_LIVE<br/>
                <strong> Value:</strong> 95Chz8bagyU<br/>
                <strong> Purpose:</strong> This cookie tries to estimate the user’s internet bandwith on our sites (that
                have built-in YouTube videos).<br/>
                <strong> Expiry date:</strong> after 8 months</p>
            <p>Further cookies that are placed when you are logged into your YouTube account:</p>
            <p>
                <strong>Name:</strong> APISID<br/>
                <strong> Value:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7121400314-<br/>
                <strong> Purpose:</strong> This cookie is used to create a profile on your interests. This data is then
                used for personalised advertisements.<br/>
                <strong> Expiry date:</strong> after 2 years</p>
            <p>
                <strong>Name:</strong> CONSENT<br/>
                <strong> Value:</strong> YES+AT.de+20150628-20-0<br/>
                <strong> Purpose:</strong> The cookie stores the status of a user’s consent to the use of various Google
                services. CONSENT also provides safety measures to protect users from unauthorised attacks.<br/>
                <strong> Expiry date: </strong>after 19 years</p>
            <p>
                <strong>Name:</strong> HSID<br/>
                <strong> Value:</strong> AcRwpgUik9Dveht0I<br/>
                <strong> Purpose:</strong> This cookie is used to create a profile on your interests. This data helps to
                display customised ads.<br/>
                <strong> Expiry date:</strong> after 2 years</p>
            <p>
                <strong>Name:</strong> LOGIN_INFO<br/>
                <strong> Value:</strong> AFmmF2swRQIhALl6aL…<br/>
                <strong> Purpose:</strong> This cookie stores information on your login data.<br/>
                <strong> Expiry date:</strong> after 2 years</p>
            <p>
                <strong>Name:</strong> SAPISID<br/>
                <strong> Value:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM<br/>
                <strong> Purpose:</strong> This cookie identifies your browser and device. It is used to create a
                profile on your interests.<br/>
                <strong> Expiry date:</strong> after 2 years</p>
            <p>
                <strong>Name:</strong> SID<br/>
                <strong> Value:</strong> oQfNKjAsI121400314-<br/>
                <strong> Purpose:</strong> This cookie stores your Google Account ID and your last login time, in a
                digitally signed and encrypted form.<br/>
                <strong> Expiry date:</strong> after 2 years</p>
            <p>
                <strong>Name:</strong> SIDCC<br/>
                <strong> Value:</strong> AN0-TYuqub2JOcDTyL<br/>
                <strong> Purpose:</strong> This cookie stores information on how you use the website and on what
                advertisements you may have seen before visiting our website.<br/>
                <strong> Expiry date:</strong> after 3 months</p>
            <h3>How long and where is the data stored?</h3>
            <p>The data YouTube receive and process on you are stored on Google’s servers. Most of these servers are in
                America. At <a decorated href="https://www.google.com/about/datacenters/inside/locations/?hl=en"
                               rel="noopener" target="_blank">https://www.google.com/about/datacenters/inside/locations/?hl=en</a>
                you can see where Google’s data centres are located. Your data is distributed across the servers.
                Therefore, the data can be retrieved quicker and is better protected against manipulation.</p>
            <p>Google stores collected data for different periods of time. You can delete some data anytime, while other
                data are automatically deleted after a certain time, and still other data are stored by Google for a
                long time. Some data (such as elements on “My activity”, photos, documents or products) that are saved
                in your Google account are stored until you delete them. Moreover, you can delete some data associated
                with your device, browser, or app, even if you are not signed into a Google Account.</p>
            <h3>How can I delete my data or prevent data retention?</h3>
            <p>Generally, you can delete data manually in your Google account. Furthermore, in 2019 an automatic
                deletion of location and activity data was introduced. Depending on what you decide on, it deletes
                stored information either after 3 or 18 months.</p>
            <p>Regardless of whether you have a Google account or not, you can set your browser to delete or deactivate
                cookies placed by Google. These settings vary depending on the browser you use. The following
                instructions will show how to manage cookies in your browser:</p>
            <p>
                <a decorated href="https://support.google.com/chrome/answer/95647?tid=121400314" rel="noopener"
                   target="_blank"> Chrome: Clear, enable and manage cookies in Chrome </a>
            </p>
            <p>
                <a decorated href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121400314"
                   rel="noopener" target="_blank"> Safari: Manage cookies and website data in Safari </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121400314"
                   rel="noopener" target="_blank"> Firefox: Clear cookies and site data in Firefox </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121400314"
                   rel="noopener" target="_blank"> Internet Explorer: Delete and manage cookies </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121400314"
                   rel="noopener" target="_blank"> Microsoft Edge: Delete cookies in Microsoft Edge </a>
            </p>
            <p>If you generally do not want to allow any cookies, you can set your browser to always notify you when a
                cookie is about to be set. This will enable you to decide to either allow or permit each individual
                cookie. Since YouTube is a subsidiary company of Google, Google’s privacy statement applies to both. If
                you want to learn more about how your data is handled, we recommend the privacy policy at <a decorated
                                                                                                             href="https://policies.google.com/privacy?hl=en"
                                                                                                             rel="noopener"
                                                                                                             target="_blank">https://policies.google.com/privacy?hl=en</a>.
            </p>
            <h2 id="youtube-abonnieren-button-en">YouTube Subscribe Button Privacy Policy</h2>
            <p>We have integrated the YouTube subscribe button to our website, which you can recognise by the classic
                YouTube logo. The logo shows the words “Subscribe” or “YouTube” in white letters against a red
                background, with a white “Play” symbol on the left. The button may also be displayed in a different
                design.</p>
            <p>Our YouTube channel consistently offers you funny, interesting or exciting videos. With the built-in
                “Subscribe” button you can subscribe to our channel directly via our website and do not need to go to
                YouTube’s website for it. With this feature, we want to make it as easy as possible for you to access
                our comprehensive content. Please note that YouTube may save and process your data.</p>
            <p>If you see a built-in subscription button on our page, YouTube sets at least one cookie, according to
                Google. This cookie stores your IP address and our URL. It also allows YouTube to receive information
                about your browser, your approximate location and your default language. In our test the following four
                cookies were placed, without us being logged into YouTube:</p>
            <p>
                <strong>Name:</strong> YSC<br/>
                <strong>Value:</strong> b9-CV6ojI5121400314Y<br/>
                <strong>Purpose:</strong> This cookie registers a unique ID, which stores statistics of the viewed
                video.<br/>
                <strong> Expiry date:</strong> after end of session</p>
            <p>
                <strong>Name:</strong> PREF<br/>
                <strong> Value:</strong> f1=50000000<br/>
                <strong> Purpose:</strong>This cookie also registers your unique ID. Google uses PREF to get statistics
                on how you interact with YouTube videos on our website.<br/>
                <strong>Expiry date:</strong> after 8 months</p>
            <p>
                <strong>Name:</strong> GPS<br/>
                <strong> Value:</strong> 1<br/>
                <strong> Purpose:</strong>This cookie registers your unique ID on mobile devices to track your GPS
                location.<br/>
                <strong> Expiry date:</strong> after 30 minutes</p>
            <p>
                <strong>Name:</strong> VISITOR_INFO1_LIVE<br/>
                <strong> Value:</strong> 12140031495Chz8bagyU<br/>
                <strong> Purpose:</strong> This cookie tries to estimate the user’s internet bandwith on our website
                (that contain built-in YouTube video).<br/>
                <strong> Expiry date:</strong> after 8 months</p>
            <p>
                <strong>Note:</strong>These cookies were set after a test, thus we do not claim for the list to be
                exhaustive.</p>
            <p>If you are logged into your YouTube account, YouTube may store many of the actions and interactions you
                make on our website via cookies, to then assign them to your YouTube account.<br/>
                This gives YouTube information on e.g. how long you have been browsing our website, which browser type
                you use, which screen resolution you prefer or what actions you take.</p>
            <p>On the one hand, YouTube uses this data to improve its own services and offers, and on the other hand to
                provide analyses and statistics for advertisers (who use Google Ads).</p>
            <h2 id="adsimple-cookie-manager-datenschutzerklaerung-en">AdSimple Cookie Manager Privacy Policy</h2>
            <p>On our website we use the AdSimple Cookie Manager of the software development and online marketing
                company AdSimple GmbH, Fabriksgasse 20, 2230 Gänserndorf, Austria. The AdSimple Cookie Manager offers
                us, among other things, the opportunity to provide you with a cookie notice that is comprehensive and
                compliant with data protection laws. This gives you the opportunity to decide which cookies you want to
                allow or deny. By using this software, data is sent from you to AdSimple, where it is stored. In this
                privacy policy we inform you on why we use the AdSimple Cookie Manager, which data is transferred and
                stored and how you can prevent this data transfer.</p>
            <h3>What is the AdSimple Cookie Manager?</h3>
            <p>The AdSimple Cookie Manager is a software that scans our website to identify and categorise all existing
                cookies. Additionally, you as the website visitor will be informed on the use of cookies via a cookie
                notice script. Then you can decide yourself which cookies you want to allow or deny.</p>
            <h3>Why do we use the AdSimple Cookie Manager on your website?</h3>
            <p>We want to offer you maximum transparency when it comes to data protection. Before we can do so, we need
                to exactly determine which cookies have ended up on our website over time. Since the AdSimple cookie
                manager regularly scans our website and locates all cookies, we have full control over these cookies and
                can therefore act in compliance with the GDPR. This enables us to inform you precisely on the use of
                cookies on our website.<br/>
                Moreover, you will always receive an up-to-date cookie notice that is data-protection compliant. This
                enables you to decide yourself which cookies you want to allow or deny via the checkbox system.</p>
            <h3>Which data are stored by the AdSimple Cookie Manager?</h3>
            <p>If you agree to cookies on our website, AdSimple Cookie Manager will set the following cookie:</p>
            <p>
                <strong>Name:</strong> acm_status<br/>
                <strong>Value:</strong>
                “:true,”statistics”:true,”marketing”:true,”socialmedia”:true,”settings”:true}<br/>
                <strong>Porpose:</strong> This cookie saves the status of your consent. This enables our website to read
                and adhere to the latest status, even during future visits.<br/>
                <strong>Expiry date:</strong> after one year</p>
            <h3>How long and where are the data stored?</h3>
            <p>All data collected by the AdSimple Cookie Manager is transferred and stored exclusively within the
                European Union. The collected data is stored on the AdSimple servers at Hetzner GmbH in Germany.
                Therefore, only AdSimple GmbH and Hetzner GmbH have access to the data.</p>
            <h3>How can I delete my data or prevent data retention?</h3>
            <p>You have the right to access and delete your personal data at any time. You can prevent data collection
                and storage by for example rejecting the use of cookies via the cookie notification script. Another
                option to prevent data processing or to manage it according to your wishes is your browser. Depending on
                the browser you use, managing cookies differs slightly. Below you will find links to the instructions
                for the most common browsers:</p>
            <p>
                <a decorated href="https://support.google.com/chrome/answer/95647?tid=121400314" rel="noopener"
                   target="_blank"> Chrome: Clear, enable and manage cookies in Chrome </a>
            </p>
            <p>
                <a decorated href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121400314"
                   rel="noopener" target="_blank"> Safari: Manage cookies and website data in Safari </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121400314"
                   rel="noopener" target="_blank"> Firefox: Clear cookies and site data in Firefox </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121400314"
                   rel="noopener" target="_blank"> Internet Explorer: Delete and manage cookies </a>
            </p>
            <p>
                <a decorated
                   href=" https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121400314"
                   rel="noopener" target="_blank"> Microsoft Edge: Delete cookies in Microsoft Edge </a>
            </p>
            <p>We hope we were able to provide you with a good overview of data traffic and data processing by AdSimple
                Cookie Manager. If you want to learn more about this tool, we recommend the description page at <a
                        decorated href="https://www.adsimple.at/adsimple-cookie-manager/?tid=121400314" rel="noopener noreferrer"
                        target="_blank">https://www.adsimple.at/adsimple-cookie-manager/.</a>
            </p>
            <h2 id="google-recaptcha-datenschutzerklaerung-en">Google reCAPTCHA Privacy Policy</h2>
            <p>Our primary goal is to provide you an experience on our website that is as secure and protected as
                possible. To do this, we use Google reCAPTCHA from Google Inc. (1600 Amphitheater Parkway Mountain View,
                CA 94043, USA). With reCAPTCHA we can determine whether you are a real person from flesh and bones, and
                not a robot or a spam software. By spam we mean any electronically undesirable information we receive
                involuntarily. Classic CAPTCHAS usually needed you to solve text or picture puzzles to check. But thanks
                to Google’s reCAPTCHA you usually do have to do such puzzles. Most of the times it is enough to simply
                tick a box and confirm you are not a bot. With the new Invisible reCAPTCHA version you don’t even have
                to tick a box. In this privacy policy you will find out how exactly this works, and what data is used
                for it.</p>
            <h3>What is reCAPTCHA?</h3>
            <p>reCAPTCHA is a free captcha service from Google that protects websites from spam software and misuse by
                non-human visitors. This service is used the most when you fill out forms on the Internet. A captcha
                service is a type of automatic Turing-test that is designed to ensure specific actions on the Internet
                are done by human beings and not bots. During the classic Turing-test (named after computer scientist
                Alan Turing), a person differentiates between bot and human. With Captchas, a computer or software
                program does the same. Classic captchas function with small tasks that are easy to solve for humans but
                provide considerable difficulties to machines. With reCAPTCHA, you no longer must actively solve
                puzzles. The tool uses modern risk techniques to distinguish people from bots. The only thing you must
                do there, is to tick the text field “I am not a robot”. However, with Invisible reCAPTCHA even that is
                no longer necessary. reCAPTCHA, integrates a JavaScript element into the source text, after which the
                tool then runs in the background and analyses your user behaviour. The software calculates a so-called
                captcha score from your user actions. Google uses this score to calculate the likelihood of you being a
                human, before entering the captcha. reCAPTCHA and Captchas in general are used every time bots could
                manipulate or misuse certain actions (such as registrations, surveys, etc.).</p>
            <h3>Why do we use reCAPTCHA on our website?</h3>
            <p>We only want to welcome people from flesh and bones on our side and want bots or spam software of all
                kinds to stay away. Therefore, we are doing everything we can to stay protected and to offer you the
                highest possible user friendliness. For this reason, we use Google reCAPTCHA from Google. Thus, we can
                be pretty sure that we will remain a “bot-free” website. Using reCAPTCHA, data is transmitted to Google
                to determine whether you genuinely are human. reCAPTCHA thus ensures our website’s and subsequently your
                security. Without reCAPTCHA it could e.g. happen that a bot would register as many email addresses as
                possible when registering, in order to subsequently “spam” forums or blogs with unwanted advertising
                content. With reCAPTCHA we can avoid such bot attacks.</p>
            <h3>What data is stored by reCAPTCHA?</h3>
            <p>reCAPTCHA collects personal user data to determine whether the actions on our website are made by people.
                Thus, IP addresses and other data Google needs for its reCAPTCHA service, may be sent to Google. Within
                member states of the European Economic Area, IP addresses are almost always compressed before the data
                makes its way to a server in the USA.<br/>
                Moreover, your IP address will not be combined with any other of Google’s data, unless you are logged
                into your Google account while using reCAPTCHA. Firstly, the reCAPTCHA algorithm checks whether Google
                cookies from other Google services (YouTube, Gmail, etc.) have already been placed in your browser. Then
                reCAPTCHA sets an additional cookie in your browser and takes a snapshot of your browser window.</p>
            <p>The following list of collected browser and user data is not exhaustive. Rather, it provides examples of
                data, which to our knowledge, is processed by Google.</p>
            <ul>
                <li>Referrer URL (the address of the page the visitor has come from)</li>
                <li>IP-address (z.B. 256.123.123.1)</li>
                <li>Information on the operating system (the software that enables the operation of your computers.
                    Popular operating systems are Windows, Mac OS X or Linux)
                </li>
                <li>Cookies (small text files that save data in your browser)</li>
                <li>Mouse and keyboard behaviour (every action you take with your mouse or keyboard is stored)</li>
                <li>Date and language settings (the language and date you have set on your PC is saved)</li>
                <li>All Javascript objects (JavaScript is a programming language that allows websites to adapt to the
                    user. JavaScript objects can collect all kinds of data under one name)
                </li>
                <li>Screen resolution (shows how many pixels the image display consists of)</li>
            </ul>
            <p>Google may use and analyse this data even before you click on the “I am not a robot” checkmark. In the
                Invisible reCAPTCHA version, there is no need to even tick at all, as the entire recognition process
                runs in the background. Moreover, Google have not given details on what information and how much data
                they retain.</p>
            <p>The following cookies are used by reCAPTCHA: With the following list we are referring to Google’s
                reCAPTCHA demo version at <a decorated href="https://www.google.com/recaptcha/api2/demo" rel="noopener"
                                             target="_blank">https://www.google.com/recaptcha/api2/demo</a>.<br/>
                For tracking purposes, all these cookies require a unique identifier. Here is a list of cookies that
                Google reCAPTCHA has set in the demo version:</p>
            <p>
                <strong>Name:</strong> IDE<br/>
                <strong>Value:</strong> WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-121400314-8<br/>
                <strong> Purpose:</strong>This cookie is set by DoubleClick (which is owned by Google) to register and
                report a user’s interactions with advertisements. With it, ad effectiveness can be measured, and
                appropriate optimisation measures can be taken. IDE is stored in browsers under the domain
                doubleclick.net.<br/>
                <strong> Expiry date:</strong> after one year</p>
            <p>
                <strong>Name:</strong> 1P_JAR<br/>
                <strong> Value:</strong> 2019-5-14-12<br/>
                <strong>Purpose:</strong> This cookie collects website usage statistics and measures conversions. A
                conversion e.g. takes place, when a user becomes a buyer. The cookie is also used to display relevant
                adverts to users. Furthermore, the cookie can prevent a user from seeing the same ad more than
                once.<br/>
                <strong> Expiry date:</strong> after one month</p>
            <p>
                <strong>Name:</strong> ANID<br/>
                <strong> Value:</strong> U7j1v3dZa1214003140xgZFmiqWppRWKOr<br/>
                <strong> Purpose:</strong>We could not find out much about this cookie. In Google’s privacy statement,
                the cookie is mentioned in connection with “advertising cookies” such as “DSID”, “FLC”, “AID” and
                “TAID”. ANID is stored under the domain google.com.<br/>
                <strong>Expiry date:</strong> after 9 months</p>
            <p>
                <strong>Name:</strong> CONSENT<br/>
                <strong> Value: </strong>YES+AT.de+20150628-20-0<br/>
                <strong> Purpose:</strong> This cookie stores the status of a user’s consent to the use of various
                Google services. CONSENT also serves to prevent fraudulent logins and to protect user data from
                unauthorised attacks.<br/>
                <strong> Expiry date:</strong> after 19 years</p>
            <p>
                <strong>Name:</strong> NID<br/>
                <strong> Value:</strong> 0WmuWqy121400314zILzqV_nmt3sDXwPeM5Q<br/>
                <strong> Purpose:</strong> Google uses NID to customise advertisements to your Google searches. With the
                help of cookies, Google “remembers” your most frequently entered search queries or your previous ad
                interactions. Thus, you always receive advertisements tailored to you. The cookie contains a unique ID
                to collect users’ personal settings for advertising purposes.<br/>
                <strong> Expiry date:</strong> after 6 months</p>
            <p>
                <strong>Name:</strong> DV<br/>
                <strong> Value:</strong> gEAABBCjJMXcI0dSAAAANbqc121400314-4<br/>
                <strong> Purpose:</strong> This cookie is set when you tick the “I am not a robot” checkmark. Google
                Analytics uses the cookie personalised advertising. DV collects anonymous information and is also used
                to distinct between users.<br/>
                <strong> Expiry date:</strong> after 10 minutes</p>
            <p>
                <strong>Note: </strong>We do not claim for this list to be extensive, as Google often change the choice
                of their cookies.</p>
            <h3>How long and where are the data stored?</h3>
            <p>Due to the integration of reCAPTCHA, your data will be transferred to the Google server. Google have not
                disclosed where exactly this data is stored, despite repeated inquiries. But even without confirmation
                from Google, it can be assumed that data such as mouse interaction, length of stay on a website or
                language settings are stored on the European or American Google servers. The IP address that your
                browser transmits to Google does generally not get merged with other Google data from the company’s
                other services.<br/>
                However, the data will be merged if you are logged in to your Google account while using the reCAPTCHA
                plug-in. <strong>Google’s diverging privacy policy applies for this.</strong>
            </p>
            <h3>How can I delete my data or prevent data storage?</h3>
            <p>If you want to prevent any data about you and your behaviour to be transmitted to Google, you must fully
                log out of Google and delete all Google cookies before visiting our website or use the reCAPTCHA
                software. Generally, the data is automatically sent to Google as soon as you visit our website. To
                delete this data, you must contact Google Support at <a decorated
                                                                        href="https://support.google.com/?hl=en-GB&tid=121400314">https://support.google.com/?hl=en-GB&tid=121400314</a>.
            </p>
            <p>If you use our website, you agree that Google LLC and its representatives automatically collect, edit and
                use data.</p>
            <p>You can find out more about reCAPTCHA on Google’s Developers page at <a decorated
                                                                                       href="https://developers.google.com/recaptcha/"
                                                                                       rel="noopener" target="_blank">https://developers.google.com/recaptcha/</a>.
                While Google do give more detail on the technical development of reCAPTCHA there, they have not
                disclosed precise information about data retention and data protection. A good, basic overview of the
                use of data however, can be found in the company’s internal privacy policy at <a decorated
                                                                                                 href="https://policies.google.com/privacy?hl=en-GB&tid=121400314"
                                                                                                 rel="noopener"
                                                                                                 target="_blank">https://policies.google.com/privacy?hl=en-GB</a>.
            </p>
            <h2 id="jquery-cdn-datenschutzerklaerung-en">jQuery CDN Privacy Policy</h2>
            <p>We use jQuery CDN services by the jQuery Foundation to deliver our website and our subpages to you
                quickly and easily on different devices. jQuery is distributed via the Content Delivery Network (CDN) of
                the American software company StackPath (LCC 2012 McKinney Ave. Suite 1100, Dallas, TX 75201, USA). This
                service stores, manages and processes your personal data.</p>
            <p>A content delivery network (CDN) is a network of regionally distributed servers that are connected to
                each other via the Internet. Through this network content and especially very large files, can be
                delivered quickly – even in peak demand periods.</p>
            <p>jQuery uses JavaScript libraries to be able to deliver our website content quickly. For this, a CDN
                server loads the necessary files. As soon as a connection to the CDN server is established, your IP
                address is recorded and stored. This only happens if the data has not already been saved in your browser
                during a previous website visit.</p>
            <p>StackPath’s privacy policy explicitly mentions that StackPath uses aggregated and anonymised data of
                various services (such as jQuery) for both, security enhancement and its own services. However, it is
                impossible for you to be personally identified with the use of this data.</p>
            <p>If you want to avoid this data transfer, you always have the option to use JavaScript blockers such as <a
                    decorated href="https://www.ghostery.com/" rel="follow noopener noreferrer" target="_blank">ghostery.com</a>
                or <a decorated href="https://noscript.net/" rel="follow noopener noreferrer" target="_blank">noscript.net</a>.
                You can also simply deactivate the execution of JavaScript codes in your browser. If you decide to
                deactivate JavaScript codes, the usual functions will also change. For example, websites may no longer
                load as quickly.</p>
            <p>StackPath is an active participant in the EU-U.S. Privacy Shield Framework, which regulates the correct
                and secure transfer of personal data. You can find more information at <a decorated
                                                                                          href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active"
                                                                                          rel="follow noopener noreferrer"
                                                                                          target="_blank">https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active</a>.<br/>
                Also, you can find more information about StackPath’s data protection at <a decorated
                                                                                            href="https://www.stackpath.com/legal/privacy-statement/?tid=121400314"
                                                                                            rel="noopener noreferrer"
                                                                                            target="_blank">https://www.stackpath.com/legal/privacy-statement/</a>
                and jQuery’s data protection at <a decorated
                                                   href="https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf"
                                                   rel="follow noopener noreferrer" target="_blank">https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf</a>.
            </p>
            <h2 id="cloudflare-datenschutzerklaerung-en">Cloudflare Privacy Policy</h2>
            <p>We use Cloudflare by the company Cloudflare, Inc. (101 Townsend St., San Francisco, CA 94107, USA) on
                this website to enhance its speed and security. For this, Cloudflare uses cookies and processes user
                data. Cloudflare, Inc. is an American company that offers a content delivery network and various
                security services. These services take place between the user and our hosting provider. In the
                following, we will try to explain in detail what all this means.</p>
            <h3>What is Cloudflare?</h3>
            <p>A content delivery network (CDN), as provided by Cloudflare, is nothing more than a network of servers
                that are connected to each other. Cloudflare has deployed servers around the world, which ensure
                websites can appear on your screen faster. Simply put, Cloudflare makes copies of our website and places
                them on its own servers. Thus, when you visit our website, a load distribution system ensures that the
                main part of our website is delivered by a server that can display our website to you as quickly as
                possible. The CDN significantly shortens the route of the transmitted data to your browser. Thus,
                Cloudflare does not only deliver our website’s content from our hosting server, but from servers from
                all over the world. Cloudflare is particularly helpful for users from abroad, since pages can be
                delivered from a nearby server. In addition to the fast delivery of websites, Cloudflare also offers
                various security services, such as DDoS protection, or the web application firewall.</p>
            <h3>Why do we use Cloudflare on our website?</h3>
            <p>Of course, we want our website to offer you the best possible service. Cloudflare helps us make our
                website faster and more secure. Cloudflare offers us web optimisations as well as security services such
                as DDoS protection and a web firewall. Moreover, this includes a <a decorated
                                                                                    href="https://de.wikipedia.org/wiki/Reverse_Proxy"
                                                                                    rel="noopener" target="_blank">Reverse-Proxy</a>
                and the content distribution network (CDN). Cloudflare blocks threats and limits abusive bots as well as
                crawlers that waste our bandwidth and server resources. By storing our website in local data centres and
                blocking spam software, Cloudflare enables us to reduce our bandwidth usage by about 60%. Furthermore,
                the provision of content through a data centre near you and certain web optimizations carried out there,
                cut the average loading time of a website in about half. According to Cloudflare, the setting “I’m Under
                Attack Mode” can be used to mitigate further attacks by displaying a JavaScript calculation task that
                must be solved before a user can access a website. Overall, this makes our website significantly more
                powerful and less susceptible to spam or other attacks.</p>
            <h3>What data is stored by Cloudflare?</h3>
            <p>Cloudflare generally only transmits data that is controlled by website operators. Therefore, Cloudflare
                does not determine the content, but the website operator themselves does. Additionally, Cloudflare may
                collect certain information about the use of our website and may process data we send or data which
                Cloudflare has received certain instructions for. Mostly, Cloudflare receives data such as IP addresses,
                contacts and protocol information, security fingerprints and websites’ performance data. Log data for
                example helps Cloudflare identify new threats. That way, Cloudflare can ensure a high level of security
                for our website. As part of their services, Cloudflare process this data in compliance with the
                applicable laws. Of course, this also includes the compliance with the General Data Protection
                Regulation (GDPR).</p>
            <p>Furthermore, Cloudflare uses a cookie for security reasons. The cookie (__cfduid) is used to identify
                individual users behind a shared IP address, and to apply security settings for each individual user.
                The cookie is very useful, if you e.g. use our website from a restaurant where several infected
                computers are located. However, if your computer is trustworthy, we can recognise that with the cookie.
                Hence, you will be able to freely and carelessly surf our website, despite the infected PCs in your
                area. Another point that is important to know, is that this cookie does not store any personal data. The
                cookie is essential for Cloudflare’s security functions and cannot be deactivated.</p>
            <h3>Cookies from Cloudflare</h3>
            <p>
                <strong>Name:</strong> __cfduid<br/>
                <strong>Value:</strong> d798bf7df9c1ad5b7583eda5cc5e78121400314-3<br/>
                <strong>Purpose:</strong> Security settings for each individual visitor<br/>
                <strong>Expiry date:</strong> after one year</p>
            <p>Cloudflare also works with third parties. They may however only process personal data after the
                instruction of Cloudflare and in accordance with the data protection guidelines and other
                confidentiality and security measures. Without explicit consent from us, Cloudflare will not pass on any
                personal data.</p>
            <h3>How long and where is the data stored?</h3>
            <p>Cloudflare stores your information primarily in the United States and the European Economic Area.
                Cloudflare can transfer and access the information described above, from all over the world. In general,
                Cloudflare stores domains’ user-level data with the Free, Pro and Business versions for less than 24
                hours. For enterprise domains that have activated Cloudflare Logs (previously called Enterprise LogShare
                or ELS), data can be stored for up to 7 days. However, if IP addresses trigger security warnings in
                Cloudflare, there may be exceptions to the storage period mentioned above.</p>
            <h3>How can I delete my data or prevent data retention?</h3>
            <p>Cloudflare only keeps data logs for as long as necessary and in most cases deletes the data within 24
                hours. Cloudflare also does not store any personal data, such as your IP address. However, there is
                information that Cloudflare store indefinitely as part of their permanent logs. This is done to improve
                the overall performance of Cloudflare Resolver and to identify potential security risks. You can find
                out exactly which permanent logs are saved at <a decorated
                                                                 href="https://developers.cloudflare.com/1.1.1.1/commitment-to-privacy/privacy-policy/privacy-policy/"
                                                                 rel="noopener" target="_blank">https://developers.cloudflare.com/1.1.1.1/commitment-to-privacy/privacy-policy/privacy-policy/</a>.
                All data Cloudflare collects (temporarily or permanently) is cleared of all personal data. Cloudflare
                also anonymise all permanent logs.</p>
            <p>In their privacy policy, Cloudflare state that they are not responsible for the content you receive. For
                example, if you ask Cloudflare whether you can update or delete content, Cloudflare will always refer to
                us as the website operator. You can also completely prevent the collection and processing of your data
                by Cloudflare, when you deactivate the execution of script-code in your browser, or if you integrate a
                script blocker to your browser.</p>
            <p>Cloudflare is an active participant in the EU-U.S. Privacy Shield Framework, which regulates the correct
                and secure transfer of personal data. You can find more information on this at <a decorated
                                                                                                  href="https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&tid=121400314"
                                                                                                  rel="noopener"
                                                                                                  target="_blank">https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0</a>.<br/>
                You can learn more on Cloudflare’s data protection at <a decorated
                                                                         href="https://www.cloudflare.com/en-gb/privacypolicy/?tid=121400314"
                                                                         rel="noopener" target="_blank">https://www.cloudflare.com/en-gb/privacypolicy/</a>.
            </p>
            <h2 id="bootstrapcdn-datenschutzerklaerung-en">BootstrapCDN Privacy Policy</h2>
            <p>In order to be able to deliver all the individual sub-pages of our website to you quickly and securely on
                all devices, we use the Content Delivery Network (CDN) BootstrapCDN of the American software company
                StackPath, LLC 2012 McKinney Ave. Suite 1100, Dallas, TX 75201, USA.</p>
            <p>A content delivery network (CDN) is a network of regionally distributed servers that are connected to
                each other via the Internet. Through this network, content – especially very large files, can be
                delivered quickly, even with large peak loads.</p>
            <p>BootstrapCDN enables the delivery of JavaScript libraries to your browser. If your browser then downloads
                a file of the BootstrapCDN, your IP address is transmitted to the company StockPath during the
                connection with the Bootstrap CDN server.</p>
            <p>In their privacy policy, StackPath also mention that the company uses aggregated and anonymised data from
                various services (such as BootstrapCDN). This is done to extend the backup, as well as for other
                StackPath services and clients. However, none of this data can be used to identify any person.</p>
            <p>If you want to prevent this data transmission, you can install a JavaScript blocker (for example at <a
                    decorated href="https://noscript.net/" rel="noopener noreferrer" target="_blank">https://noscript.net/</a>),
                or deactivate the execution of JavaScript codes in your browser. However, please note that this means
                that the website then can no longer offer its usual services (such as fast loading speeds).</p>
            <p>StackPath is an active participant in the EU-U.S. Privacy Shield Framework, which regulates the correct
                and secure transfer of personal data. More information on this can be found at <a decorated
                                                                                                  href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active"
                                                                                                  rel="follow noopener noreferrer"
                                                                                                  target="_blank">https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active</a>.<br/>
                More information on data protection at StackPath or BootstrapCDN can be found at <a decorated
                                                                                                    href="https://www.bootstrapcdn.com/privacy-policy/?tid=121400314"
                                                                                                    rel="noopener noreferrer"
                                                                                                    target="_blank">https://www.bootstrapcdn.com/privacy-policy/</a>.
            </p>
            <p style="margin-top:15px;">Source: Created with the Datenschutz Generator by <a decorated
                                                                                             href="https://www.adsimple.at/online-marketing/"
                                                                                             rel="follow"
                                                                                             target="_blank"
                                                                                             title="AdSimple® Online Marketing and Webdesign">AdSimple®
                Online Marketing</a> in cooperation with <a decorated href="https://www.schoenheitsmagazin.at/"
                                                            rel="follow" target="_blank">schoenheitsmagazin.at</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Imprint",
    metaInfo: {
        title: 'Imprint',
        meta: [
            {property: 'og:title', content: 'Imprint'},
            {property: 'og:site_name', content: 'strukteon.net'},
            {property: 'og:type', content: 'website'},
            {property: 'og:description', content: 'Imprint + Privacy Policy'}
        ]
    }
}
</script>

<style scoped>
.imprint-wrapper {
    width: 100%;
    background-attachment: fixed;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("~@/assets/images/fog.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
}

#imprint-content {
    width: 80em;
    max-width: 100%;
}
</style>